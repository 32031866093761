import React from 'react';
import AuthenticationManager from '../Managers/Authentication';
import TranslationManager from '../Managers/Translation';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

export default class CardsTotalsReport extends React.Component {
    static contextType = AuthenticationManager.Context;

    render() {
        if (!this.props.cards) return <Spinner animation="border" variant="secondary" style={{marginTop:"8px",marginLeft:"calc(50% - 16px)"}}/>

        let cards = this.props.cards;
        let visitedCards = cards.filter(card => {
            if (!card.history) return false;
            for (let i = 0; i < card.history.length; ++i) {
                let dtFrom = new Date(card.history[i].fromDate);
                if (dtFrom > this.props.dateTo) continue;
                if (!card.history[i].toDate) return true;
                var dtTo = new Date(card.history[i].toDate);
                if (dtTo < this.props.dateFrom) return false;
                return true;
            }
            return false;
        });

        let visitedGroupCards = visitedCards.filter(card => {
            if (!card.history) return false;
            for (let i = 0; i < card.history.length; ++i) {
                if (card.history[i].group != this.context.getUserData().group) continue;
                let dtFrom = new Date(card.history[i].fromDate);
                if (dtFrom > this.props.dateTo) continue;
                if (!card.history[i].toDate) return true;
                var dtTo = new Date(card.history[i].toDate);
                if (dtTo < this.props.dateFrom) return false;
                return true;
            }
            return false;
        });

        let returnedCards = cards.filter(card => {
            if (!card.history) return false;
            for (let i = 0; i < card.history.length; ++i) {
                if (!card.history[i].toDate) return false;
                var dtTo = new Date(card.history[i].toDate);
                if (dtTo < this.props.dateFrom) return false;
                if (dtTo > this.props.dateTo) return false;
                return true;
            }
            return false;
        });

        let returnedGroupCards = cards.filter(card => {
            if (!card.history) return false;
            for (let i = 0; i < card.history.length; ++i) {
                if (card.history[i].group != this.context.getUserData().group) continue;
                if (!card.history[i].toDate) return false;
                var dtTo = new Date(card.history[i].toDate);
                if (dtTo < this.props.dateFrom) return false;
                if (dtTo > this.props.dateTo) return false;
                return true;
            }
            return false;
        });

        return (
            <TranslationManager.Context.Consumer>
            { (context) => (
                <React.Fragment>
                    <Row className="no-gutters">
                        <Col xs={11} md={6} lg={3}>{context.getTranslation("$total_cards_count")}</Col>
                        <Col xs={1}>{cards.length}</Col>
                        <Col xs="auto"/>
                    </Row>
                    <Row className="no-gutters">
                        <Col xs={11} md={6} lg={3}>{context.getTranslation("$total_visited_cards_count")}</Col>
                        <Col xs={1}>{visitedCards.length}</Col>
                        <Col xs="auto"/>
                    </Row>
                    <Row className="no-gutters">
                        <Col xs={11} md={6} lg={3}>{context.getTranslation("$total_visited_group_cards_count")}</Col>
                        <Col xs={1}>{visitedGroupCards.length}</Col>
                        <Col xs="auto"/>
                    </Row>
                    <Row className="no-gutters">
                        <Col xs={11} md={6} lg={3}>{context.getTranslation("$total_returned_cards_count")}</Col>
                        <Col xs={1}>{returnedCards.length}</Col>
                        <Col xs="auto"/>
                    </Row>
                    <Row className="no-gutters">
                        <Col xs={11} md={6} lg={3}>{context.getTranslation("$total_returned_group_cards_count")}</Col>
                        <Col xs={1}>{returnedGroupCards.length}</Col>
                        <Col xs="auto"/>
                    </Row>
                </React.Fragment>
            )}
            </TranslationManager.Context.Consumer>
        );
    }
}