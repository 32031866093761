

if (!Date.prototype.format) {
  

/*helper function for managing datetimes*/
Date.prototype.format = function(format) {
    if (!format) return this.toString();
    var d = this.getDate();
    var M = this.getMonth() + 1;
    var y = this.getFullYear();
    var H = this.getHours();
    var m = this.getMinutes();
    var s = this.getSeconds();
    var f = this.getMilliseconds();
    var dd = (d < 10 ? "0" : "") + d;
    var MM = (M < 10 ? "0" : "") + M;
    var yy = y % 100;
    yy = (yy < 10 ? "0" : "") + yy;
    var yyyy = y;
    var HH = (H < 10 ? "0" : "") + H;
    var mm = (m < 10 ? "0" : "") + m;
    var ss = (s < 10 ? "0" : "") + s;
    var fff = (f < 100 ? "0" : "") + (f < 10 ? "0" : "") + f;
    format = format.replace("dd", dd);
    format = format.replace("d", d);
    format = format.replace("MM", MM);
    format = format.replace("M", M);
    format = format.replace("yyyy", yyyy);
    format = format.replace("yy", yy);
    format = format.replace("y", y);
    format = format.replace("HH", HH);
    format = format.replace("H", H);
    format = format.replace("mm", mm);
    format = format.replace("m", m);
    format = format.replace("ss", ss);
    format = format.replace("s", s);
    format = format.replace("fff", fff);
    format = format.replace("ff", fff);
    format = format.replace("f", f);
    return format;
  };
  

}

