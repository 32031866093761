import React from 'react';
import DataManager from '../Managers/Data';
import CardListView from '../Views/CardList';
import CardView from '../Views/Card';
import MapView from '../Views/Map';
import GiveoutDialog from '../Dialogs/GiveoutDialog';
import ReturnDialog from '../Dialogs/ReturnDialog';
import NewCardDialog from '../Dialogs/NewCardDialog';
import Card from '../Entities/Card';
import TranslationManager from '../Managers/Translation';

import {FullRow,FullCol} from '../Components/BootstrapExtensions';

function romanize(num) {
    var lookup = {M:1000,CM:900,D:500,CD:400,C:100,XC:90,L:50,XL:40,X:10,IX:9,V:5,IV:4,I:1},roman = '',i;
    for ( i in lookup ) {
        while ( num >= lookup[i] ) {
            roman += i;
            num -= lookup[i];
        }
    }
    return roman;
}

export default class CardsPage extends React.Component {
    static contextType = DataManager.Context;

    constructor(props) {
        super(props);
        this.state = {
            cardNumber: props.routing.getParameter("card") || props.routing.getParameter("map")
        }
    }

    componentDidMount() {
        this.refreshList();
        this.refreshCard();

        this.refreshListInterval = setInterval(() => this.refreshList(), 30 * 1000);
    }

    componentWillUnmount() {
        clearInterval(this.refreshListInterval);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let newNumber = this.props.routing.getParameter("card") || this.props.routing.getParameter("map");
        if (newNumber == this.state.cardNumber) return;
        if (newNumber)
            this.setState({cardNumber: newNumber, loading:true, inEditMode:false}, () => this.refreshCard());
        else
            this.setState({cardNumber: newNumber, loading:false, inEditMode:false}, () => this.refreshCard());
    }

    refreshList() {
        this.context.fetchCardList((list) => this.setState({cardList: list}));
    }

    refreshCard() {
        if (!this.state.cardNumber) {
            if (this.state.card)
                this.setState({card:undefined});
            return;
        }

        this.context.fetchCard(this.state.cardNumber, (card) => {
            this.setState(prevState => {
                if (prevState.cardNumber != card.number)
                    return prevState;
                return {...prevState, card:card, loading:false};
            });
        });
    }

    selectCard(number) {
        if (this.state.blockInEditMode) return;

        if (!number) this.props.routing.setRoute("");

        this.props.routing.setRoute("?card="+number);
    }

    selectMap(number) {
        if (!number) this.props.routing.setRoute("");
        
        this.props.routing.setRoute("?map="+number);
    }

    openLink(link, title, description) {
        if (navigator.share) {
            navigator.share({
                title: title,
                text: description,
                url: link
            })
            .then(() => console.log('Successful share'))
            .catch(error => console.log('Error sharing:', error));
        } else
            window.open(link);
    }

    onShareLink(card) {
        //hotfix
        if (!card.token)
        {
            let newCard = this.state.card.generateToken();
            this.context.saveCard(
                newCard
                ,() => {
                    this.openLink("/viewcard.html?token=" + newCard.token, card.number, card.description);
                }
            );
        }
        else
        {
            this.openLink("/viewcard.html?token=" + card.token, card.number, card.description);
        }
    }

    //common card change handler
    onChange(newCard) { this.setState({card: newCard}); }

    //Editin handlers
    onEditStart() { this.setState({inEditMode: true}); }
    onEditSave() {
        this.context.saveCard(this.state.card,() => {
            this.setState({inEditMode:false, cardNumber:this.state.card.number}, () => {
                this.props.routing.setRoute("?card="+this.state.card.number);
                this.refreshCard();
                this.refreshList();
            });
        });
    }
    onEditCancel() { this.setState({inEditMode: false}, () => this.refreshCard()); }

    //GiveoutDialog event handlers
    onGiveout(data) {
        this.context.saveCard(
            this.state.card.addHistory({
                 who: data.person
                ,group: data.group
                ,fromDate: data.date
            })
            .generateToken()

            ,() => {
                this.setState({showGiveoutDialog:false, loading:true, cardNumber:this.state.card.number}, () => {
                    this.refreshCard();
                    this.refreshList();
                });
            }
        );
    }
    onGiveoutCancel() {
        this.setState({showGiveoutDialog:false});
    }

    //ReturnDialog event handlers
    onReturn(data) {
        this.context.saveCard(
            this.state.card.updateHistory(0, {toDate: data.date}).clearToken()
            ,() => {
                this.setState(
                    {
                        showReturnDialog:false,
                        loading:true,
                        cardNumber:this.state.card.number
                    }, () => {
                        this.refreshCard();
                        this.refreshList();
                    }
                );
            }
        );
    }
    onReturnCancel() {
        this.setState({showReturnDialog:false});
    }

    onDelete(translation) {
        let cardNumber = prompt(translation.getTranslation("$delete_text"));
        if (cardNumber == this.state.card.number)
        {
            this.context.removeCard(this.state.card);
            this.refreshList();
            this.props.routing.setRoute("/app/cards");
        }
    }

    onSplit() {
        let count = prompt("Кол-во:");
        if (count && count > 1) {
            var rawCard =  {...this.state.card.rawObject};
            delete rawCard._rev;

            for (let i = 2; i <= count; ++i)
            {
                rawCard._id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                rawCard.number = this.state.card.number + "-" + romanize(i);

                this.context.saveCard(new Card(rawCard));
            }

            //this.props.routing.setRoute("?card="+this.state.card.number + "-" + romanize(1));
            let newNumber = this.state.card.number + "-" + romanize(1);
            this.context.saveCard(
                this.state.card.update({number: newNumber})
                ,() => {
                    this.refreshList();
                    this.props.routing.setRoute("?card="+newNumber);
                }
            );
        }
    }

    onNewCard(data) {
        if (this.state.blockInEditMode) return;

        
        var newNumber = data.number
        var groups = data.groups.split(",").map(x => x.trim());

        //TODO insert new card
        this.context.saveCard(
            new Card(
                {
                     _id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
                    ,number: newNumber
                    ,description: "<description>"
                    ,groups: groups
                    ,locations: [{address: "<address>"}]
                    ,map: {}
                    ,tags: ["dirty","toprint","nopins"]
                    ,ownerGroup:groups[0]
                    ,history: []
                }
            )
            ,() => {
                this.refreshList();
                this.props.routing.setRoute("?card="+newNumber);
                this.setState({showNewCardDialog:false});
            }
        );
    }

    onNewCardCancel() {
        this.setState({showNewCardDialog:false});
    }

    render() {
        let isCardView = !!this.props.routing.getParameter("card");
        let isMapView = !!this.props.routing.getParameter("map");
        let isListView = !isCardView && !isMapView;

        //IMPORTANT!
        //hack for rerendering leaflet after transition display:none => display:block
        //due to bug/"feature" in leaflet.js
        let shouldRerenderMap = !this.prevWasMapView && isMapView;
        // SK @ 2022-01-23: it seems that the hack is not needed any more
        //if (this.state.shouldRerenderMap != shouldRerenderMap)
          //  this.setState({shouldRerenderMap: shouldRerenderMap}); 
        this.prevWasMapView = isMapView;

        return (
            <TranslationManager.Context.Consumer>
            { (translationContext) => (
                <FullRow>

                    <FullCol xs={isListView*12} sm={isListView*12} md={3} lg={3} className={isListView?"":"col-0 col-sm-0"}>
                        <CardListView
                            list={this.state.cardList}
                            onCardSelect={(number) => this.selectCard(number)}
                            onNewCard={() => this.setState({showNewCardDialog: true})}
                        />
                    </FullCol>

                    <FullCol xs={isCardView*12} sm={isCardView*12} md={5} lg={4} className={isCardView?"":"col-0 col-sm-0"}>
                        <CardView
                            card={this.state.loading?null:this.state.card}
                            readOnly={!this.state.inEditMode}

                            onChange={(newObj) => this.onChange(newObj)}

                            onShareLink={(card) => this.onShareLink(card)}

                            onMapSelect={(number) => this.selectMap(number)}

                            blockInEditMode={this.state.blockInEditMode}

                            onEditStart={() => this.onEditStart()}
                            onEditCancel={() => this.onEditCancel()}
                            onEditSave={() => this.onEditSave()}
                            onDelete={() => this.onDelete(translationContext)}

                            onGiveout={() => this.setState({showGiveoutDialog: true})}
                            onReturn={() => this.setState({showReturnDialog: true})}
                            onSplit={() => this.onSplit()}
                        />
                    </FullCol>

                    <FullCol xs={isMapView*12 } sm={isMapView*12 } md={4} lg={5} className={isMapView?"":"col-0 col-sm-0"}>
                        <MapView
                            card={shouldRerenderMap?null:this.state.card}
                            editMode={this.state.inEditMode}
                            blockInEditMode={this.state.blockInEditMode}
                            onEditStart={() => this.setState({blockInEditMode:true })}
                            onEditStop ={() => this.setState({blockInEditMode:false})}
                            //onChange={(newMap) => this.onChange(this.state.card.update({newMap}))}
                            onChange={(newObj) => this.onChange(this.state.card.update(newObj))}
                        />
                    </FullCol>

                    <GiveoutDialog
                        show={this.state.showGiveoutDialog}
                        onGiveout = {(data) => this.onGiveout(data)}
                        onCancel = {() => this.onGiveoutCancel()}
                    />

                    <ReturnDialog
                        show={this.state.showReturnDialog}
                        onReturn = {(data) => this.onReturn(data)}
                        onCancel = {() => this.onReturnCancel()}
                    />

                    <NewCardDialog
                        show={this.state.showNewCardDialog}
                        onCreate = {(data) => this.onNewCard(data)}
                        onCancel = {() => this.onNewCardCancel()}
                    />
                </FullRow>
            )}
            </TranslationManager.Context.Consumer>
        );
    }
}