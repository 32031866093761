import React from 'react';
import Form from 'react-bootstrap/Form';

import ExternalDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class DatePicker extends React.Component {
    render() {
        var element = document.getElementById("datepicker-tester");

        //if has native datepicking - use it
        if (element && element.type == "date") {
            var date = this.props.selected || new Date();
            return (
                <Form.Control
                    style={{width:"auto",display:"inline"}}
                    type="date"
                    placeholder={this.props.placeholder}
                    value={date.format("yyyy-MM-dd")}
                    onChange={(event) => this.props.onChange(new Date(event.target.value))}
                />
            );
        }

        //otherwise use external datepicker
        return <ExternalDatePicker {...this.props} className="form-control"/>
    }
}