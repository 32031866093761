import React from 'react';
import TranslationManager from '../Managers/Translation';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import {Remove as RemoveIcon, ArrowUp as ArrowUpIcon, ArrowDown as ArrowDownIcon} from './Icons';
import RefusalsField from './RefusalsField';

export default class CardTemplate extends React.Component {
    render() {
        let card = this.props.card;
        if (!card) return null;

        return (
            <TranslationManager.Context.Consumer>
            { (context) => (
                <React.Fragment>
                    <ListGroup>
                        <ListGroup.Item>
                            <div className="d-flex">
                                <Form.Control
                                    as="textarea"
                                    plaintext={this.props.readOnly}
                                    style={{resize: "none"}}

                                    readOnly={this.props.readOnly}
                                    value={card.description}
                                    onChange={(event) => this.props.onChange(this.props.card.update({description: event.target.value}))}
                                />
                                <Form.Control
                                    plaintext={this.props.readOnly}
                                    style={{textAlign:"right", fontWeight:"bold", fontSize:"2em"}}

                                    readOnly={this.props.readOnly}
                                    value={card.number}
                                    onChange={(event) => this.props.onChange(this.props.card.update({number: event.target.value}))}
                                />
                            </div>
                        </ListGroup.Item>
                    </ListGroup>
                    <ListGroup className="flex-fill" style={{overflow:"auto"}}>
                        {card.locations.map((item,index) => {
                            /*
                            <Form.Control plaintext readOnly={false} defaultValue={item.info}
                                                style={{marginBottom:"0.25em", fontSize:"0.75em",fontStyle:"italic", color:"rgba(0,0,0,0.7)"}}
                                            />
                            */
                            let isFirst = index == 0;
                            let isLast = index == card.locations.length - 1;
                            return (
                                <ListGroup.Item key={index}>
                                    <div className="d-flex">
                                        <Col>
                                            {(!this.props.readOnly) &&
                                                <Form.Control
                                                    xs="auto"
                                                    plaintext={this.props.readOnly}
                                                    readOnly={this.props.readOnly}
                                                    value={item.address || ""}
                                                    onChange={(event) => this.props.onChange(this.props.card.updateLocation(index,{address:event.target.value}))}
                                                />
                                            }
                                            { this.props.readOnly &&
                                                <a href={item.link}>{item.address}</a>
                                            }
                                            {(!this.props.readOnly) &&
                                                <div style={{marginLeft:"0.5em", marginTop:"0.5em"}}>
                                                    <div style={{fontSize:"0.75em", color:"rgba(0,0,0,0.7)"}}>Линк</div>
                                                    <Form.Control
                                                        plaintext={this.props.readOnly}
                                                        readOnly={this.props.readOnly}
                                                        value={item.link || ""}
                                                        onChange={(event) => this.props.onChange(this.props.card.updateLocation(index,{link:event.target.value}))}
                                                    />
                                                </div>
                                            }
                                            {(!this.props.readOnly || (item.comments && item.comments.length > 0)) &&
                                                <div style={{marginLeft:"0.5em", marginTop:"0.5em"}}>
                                                    <div style={{fontSize:"0.75em", color:"rgba(0,0,0,0.7)"}}>Доп. информация</div>
                                                    <Form.Control
                                                        plaintext={this.props.readOnly}
                                                        readOnly={this.props.readOnly}
                                                        value={item.comments || ""}
                                                        onChange={(event) => this.props.onChange(this.props.card.updateLocation(index,{comments:event.target.value}))}
                                                    />
                                                </div>
                                            }
                                            {(!this.props.readOnly || (item.codes && item.codes.length > 0)) &&
                                                <div style={{marginLeft:"0.5em", marginTop:"0.5em"}}>
                                                    <div style={{fontSize:"0.75em", color:"rgba(0,0,0,0.7)"}}>Коды</div>
                                                    <Form.Control
                                                        plaintext={this.props.readOnly}
                                                        readOnly={this.props.readOnly}
                                                        value={item.codes || ""}
                                                        onChange={(event) => this.props.onChange(this.props.card.updateLocation(index,{codes:event.target.value}))}
                                                    />
                                                </div>
                                            }
                                            {(!this.props.readOnly || (item.refusals && Object.keys(item.refusals).length > 0)) &&
                                                <div style={{marginLeft:"0.5em", marginTop:"0.5em"}}>
                                                    <div style={{fontSize:"0.75em", color:"rgba(0,0,0,0.7)"}}>Не посещать</div>
                                                    <RefusalsField
                                                        plaintext={this.props.readOnly}
                                                        readOnly={this.props.readOnly}
                                                        value={item.refusals || {}}
                                                        onChange={(newValue) => this.props.onChange(this.props.card.updateLocation(index,{refusals:newValue}))}
                                                    />
                                                </div>
                                            }
                                        </Col>
                                        {!this.props.readOnly &&
                                            <Col xs="auto" style={{margin:"0.125em"}}>
                                                <div>
                                                    <Button
                                                        variant="danger"
                                                        onClick={(event) => this.props.onChange(this.props.card.removeLocation(index))}
                                                    >
                                                        <RemoveIcon/>
                                                    </Button>
                                                </div>

                                                {!isFirst && <div style={{marginTop:"0.125em"}}>
                                                    <Button onClick={(event) => this.props.onChange(this.props.card.moveLocation(index, index - 1))}>
                                                        <ArrowUpIcon/>
                                                    </Button>
                                                </div>}

                                                {!isLast && <div style={{marginTop:"0.125em"}}>
                                                    <Button onClick={(event) => this.props.onChange(this.props.card.moveLocation(index, index + 1))}>
                                                        <ArrowDownIcon/>
                                                    </Button>
                                                </div>}
                                            </Col>
                                        }
                                    </div>
                                </ListGroup.Item>
                            );
                        })}
                    </ListGroup>
                    <ListGroup>
                        {!this.props.readOnly &&
                            <ListGroup.Item>
                                <Button
                                    style={{width:"100%"}}
                                    onClick={(event) => this.props.onChange(this.props.card.addLocation({}))}
                                >{context.getTranslation("$add_location")}</Button>
                            </ListGroup.Item>
                        }
                    </ListGroup>
                </React.Fragment>
            )}
            </TranslationManager.Context.Consumer>
        )
    }
}