import React from 'react';
import TranslationManager from '../Managers/Translation';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';


export default class LoginForm extends React.Component {
    state = {}

    render() {
        return (
            <TranslationManager.Context.Consumer>
            { (context) => (
                <Modal show={this.props.show} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">{context.getTranslation("$login")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Form onSubmit={(event) => {event.preventDefault(); this.props.onLogin(this.state)}}>
                        <Form.Group controlId="formBasicUser">
                            <Form.Label>{context.getTranslation("$username")}</Form.Label>
                            <Form.Control type="text" placeholder={context.getTranslation("$username") + "..."} onChange={(event) => this.setState({username:event.target.value})}/>
                        </Form.Group>
                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>{context.getTranslation("$password")}</Form.Label>
                            <Form.Control type="password" placeholder={context.getTranslation("$password") + "..."} onChange={(event) => this.setState({password:event.target.value})}/>
                        </Form.Group>
                        <Button variant="primary" type="submit">{context.getTranslation("$login")}</Button>
                    </Form>
                    </Modal.Body>
                </Modal>
            )}
            </TranslationManager.Context.Consumer>
        );
    }
}