import React from 'react'
import './LeafletPatch';
import { MapContainer, Marker, Polygon, TileLayer, FeatureGroup, AttributionControl, useMapEvents } from 'react-leaflet'
import PinsInput from "./PinsInput";
// import { EditControl } from "react-leaflet-draw";
import EditControl from "./EditControl"; // ours, original refactored
import TranslationManager from '../Managers/Translation';
import $ from "jquery";

function MyEvents(props) {

    const map = useMapEvents({
        moveend() {
            if (props.onMoveEnd) {
                //setTimeout(()=>props.onMoveEnd(map),0); // let finish the current map transition
                props.onMoveEnd(map);
            }
        },
        zoomend() {
            if (props.onZoomEnd) {
                //setTimeout(()=>props.onZoomEnd(map),0); // let finish the current map transition
                props.onZoomEnd(map);
            }
        }
    });
    return null;
}


/**
 * <MapComponent.../>
 * 
 * Supported React props:
 * @param map (required) a card.map field from the noSQL database (as is)
 * @param {string} tilesURL (required) a string representing the tiles URL using the {z}/{x}/{y} notation;
 *                             the value is usually one of the values of the window.terman_map_providers object defined in Var/;
 *                             the provider name (the key, which is used to get the value) is usually read from card.map.simpleTiles, card.map.s12Tiles, or is just the first map provider defined in Var/ (the parent decides on that)
 * @param {function} [geocode] a function to invoke on an editable map (if editable==true) when a plain text address is being entered instead of an array of pins; usually, window.terman_geocode
 * @param {boolean} [editable=false] whether the given map is editable (i.e., the palette and the input field for the coos array is displayed)
 * @param {function} [onChange] the callback function to be called when something has been edited on a map; the function receives only the card.map field, e.g., onChange({map: newMap})
 * @param {function} [onEditStart] the void callback function to be called when some editing has been started (e.g., a polygon is being drawn); the parent function can use it to disable certain UI elements during drawing
 * @param {function} [onEditStop] the void callback function to be called when editing has been finished or cancelled (in the former case, onChange will also be called)
 */
export default class MapComponent extends React.Component {

    static contextType = TranslationManager.Context;


    constructor(props) {
        super(props);
        this.mapRef = React.createRef();
        this.featureRef = React.createRef();

        this.callOnEditStart = this.callOnEditStart.bind(this);
        this.callOnEditStop = this.callOnEditStop.bind(this);
    }

    getDefaultPosition() {
        return [56.944, 24.243, 17];
    }


    getPosition(map) {
        if (!map)
            return this.getDefaultPosition();

        //use explicit parameters if they exist
        if (map.simpleLat && map.simpleLon)
            return [map.simpleLat, map.simpleLon, map.simpleZoom || 17];


        //for single pin, use pin's coordinates with default zoom (17)
        if (map.pins && map.pins.length == 1 && map.pins[0].length == 1) {
            return [map.pins[0][0].lat, map.pins[0][0].lon, map.simpleZoom || 17];
        }

        //for multiple pins/polygons calculate:
        //  position as a center of a bounding rectangle
        //  zoom as a maximal zoom when bounding rectangle fits into a single tile (both for lat/lon) with adjustment
        if (map.pins && map.pins.length && (map.pins.length > 1 || map.pins[0].length > 1)) {
            let minLat = Math.min(...map.pins.flat().map(x => x.lat));
            let maxLat = Math.max(...map.pins.flat().map(x => x.lat));
            let minLon = Math.min(...map.pins.flat().map(x => x.lon));
            let maxLon = Math.max(...map.pins.flat().map(x => x.lon));

            let lat = (minLat + maxLat) / 2;
            let lon = (minLon + maxLon) / 2;
            let zoom = Math.min(17,                                         //limit zoom to 17, higher than 17 is too close
                Math.trunc(
                    Math.min(
                        Math.log2(360 * 0.4 / (maxLat - minLat)),   //0.4 - approximation for 50-60 latitude
                        Math.log2(360 / (maxLon - minLon))
                    ) + 1.0                                     //0.8 - imperical adjustment to zoom
                )
            );
            return [lat, lon, zoom];
        }



        return this.getDefaultPosition();
    }


    callOnChange(changedMap) {
        if (this.props.onChange) {
            let map = $.extend(true, {}, this.props.map ? this.props.map : {}, changedMap);

            if (changedMap.pins) {
                delete map.simpleLat;
                delete map.simpleLon;
                delete map.s12lat;
                delete map.s12lon;
                map.s12pins = true; // force to print pins on the S-12 blank

                map.pins = JSON.parse(JSON.stringify(changedMap.pins));
            }


            let newPos = this.getPosition(map);
            map.simpleLat = newPos[0];
            map.simpleLon = newPos[1];

            if (changedMap.pins) {
                map.simpleZoom = newPos[2]; // override with the newly computed zoom
            }

            if (changedMap.simpleZoom) {
                map.simpleZoom = changedMap.simpleZoom; // override with the fresh zoom
            }

            if (!this.inRender) {
                this.props.onChange(map);
            }
        }
    }

    shouldComponentUpdate() {
        //return !this.editing;
        return true;
    }

    callOnEditStart() {
        this.editing = true;

        let layers = this.getFeatureGroup().getLayers();
        for (let i=0; i<layers.length; i++) {
            layers[i].editing.enable();
        }

        if (this.props.onEditStart)
            this.props.onEditStart();

    }

    callOnEditStop() {
        this.editing = false;
        let layers = this.getFeatureGroup().getLayers();
        if (this.props.onEditStop) {
            this.props.onEditStop();
        }
    }
    

    getMapObj() {
        return this.mapRef.current.leafletElement;
    }

    getFeatureGroup() {
       return this.featureRef.current;
    }

    componentDidUpdate(prevProps, prevState) {
        this.updatePinIndices();
    }

    updatePinIndices() {
        if (!this.getFeatureGroup())
            return; 

        let layers = this.getFeatureGroup().getLayers();
        for (let i=0; i<layers.length; i++) {
            layers[i].pinIndex = i;
        }
    }

    onDrawCreated(e) {
        let map = this.props.map || {};
        let pins = map.pins || [];
        pins = JSON.parse(JSON.stringify(pins)); // clone

        let layer = e.layer;
        this.getFeatureGroup().removeLayer(layer); // this layer will be re-rendered after callOnChange

        if (layer.editing._marker && layer.editing._marker._latlng) {
            pins.push([{ lat: layer.editing._marker._latlng.lat, lon: layer.editing._marker._latlng.lng }]);

            let myThis = this;
            //setTimeout(function () { // allow leaflet to close its menus
                myThis.callOnChange({ pins: pins });
            //}, 0);
        }
        else
            if (layer.editing.latlngs) {

                let _coos = layer.editing.latlngs[0][0];
                let coos = [];
                for (let i = 0; i < _coos.length; i++)
                    coos.push({ lat: _coos[i].lat, lon: _coos[i].lng });

                pins.push(coos);

                let myThis = this;
                //setTimeout(function () { // allow leaflet to close its menus
                    myThis.callOnChange({ pins: pins });
                //}, 0);
            }
    }

    onDrawEdited(e) {
        let map = this.props.map || {};
        let pins = map.pins || [];
        pins = JSON.parse(JSON.stringify(pins)); // clone

        let layers = e.layers;
        this.updatePinIndices(); // important, if we use EditControl from EditControl.jsx, since it may change the ordering of layers

        layers.eachLayer(function (layer) {
            if (layer._latlng) {
                // marker moved
                pins[layer.pinIndex] = [{lat: layer._latlng.lat, lon: layer._latlng.lng}];
            }
            else
            if (layer._latlngs) {
                // polygon edited

                let _coos = layer._latlngs[0];
                let coos = [];
                let k;
                for (k=0; k<_coos.length; k++) {
                    coos.push({lat:_coos[k].lat, lon:_coos[k].lng});
                }

                pins[layer.pinIndex] = coos;
                
            }
        });

        let myThis = this;
        myThis.callOnChange({ pins: pins });
    }



    onDrawDeleted(e) {
        let pins = [];

        let remainingLayers = this.getFeatureGroup().getLayers();
        for (let i=0; i<remainingLayers.length; i++) {
            let layer=remainingLayers[i];
            if (layer._latlng) {
                // marker
                pins.push( [{lat: layer._latlng.lat, lon: layer._latlng.lng}] );
            }
            else
            if (layer._latlngs) {
                // polygon

                let _coos = layer._latlngs[0];
                let coos = [];
                let k;
                for (k=0; k<_coos.length; k++) {
                    coos.push({lat:_coos[k].lat, lon:_coos[k].lng});
                }

                pins.push( coos );
                
            }
        }

        this.updatePinIndices(); 

        let myThis = this;
        myThis.callOnChange({ pins: pins });
    }


    render() {
        this.inRender = true;

        let map = this.props.map || {};
        let pins = map.pins || [];

        let position = this.getPosition(map);

        // by SK @ 2022-10-15: fixing a bug of React-Leaflet MapContainer not updating its center and zoom
        if (this.mapRef.current) {
            // by SK @ 2022-11-02: do not move while editing
            if (!this.props.editable) {
                this.mapRef.current.setView(position.slice(0, 2), position[2]);
            }
        }

        let inputControl = null;
        if (this.props.editable) {
                // adding editable controls...
                inputControl = <PinsInput pins={this.props.map.pins} geocode={this.props.geocode} onChange={(pins) => { this.callOnChange({ pins: pins }) }} />;
        }

        let keyPrefix = new Date().getTime();
            // ^^^ force React to create new child components for pins each time; 
            // otherwise, both React and Leaflet try to reuse existing componentes/layers, and that leads to strange behaviour

        //attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        let retVal = (
            <div style={{ height: "100%" }}>
                <MapContainer
                    ref={this.mapRef}
                    center={position.slice(0, 2)}
                    zoom={position[2]}
                    attributionControl={false}
                    style={{ width: "100%", height: "100%" }}
                >
                    <MyEvents 
                        /*onZoomEnd={(map) => this.callOnChange({ simpleZoom: map.getZoom() }) }*/
                        // since onMoveEnd is always called after zoom, we do not handle onZoomEnd any more
                        onMoveEnd={(map) => this.callOnChange({ simpleLat: map.getCenter().lat, simpleLon: map.getCenter().lng, simpleZoom: map.getZoom() })}
                    />
                    <TileLayer url={this.props.tilesURL} />

                    <FeatureGroup ref={this.featureRef}>
                        
                        <EditControl
                            position='topright'
                            isVisible={this.props.editable}
                            onCreated={(e) => this.onDrawCreated(e)}
                            onEdited={(e) => this.onDrawEdited(e)}
                            onDeleted={(e) => this.onDrawDeleted(e)}

                            
                            onDrawStart={this.callOnEditStart}
                            onEditStart={this.callOnEditStart}
                            onDeleteStart={this.callOnEditStart}

                            onDrawStop={this.callOnEditStop}
                            onEditStop={this.callOnEditStop}
                            onDeleteStop={this.callOnEditStop}

                            draw={{
                                rectangle: false,
                                polyline: false,
                                circle: false,
                                circlemarker: false,
                                marker: true,
                                polygon: {
                                    allowIntersection: false, // Restricts shapes to simple polygons
                                    drawError: {
                                        color: '#e1e100', // Color the shape will turn when intersects
                                        message: this.context.getTranslation('$cannot_draw_that') // Message that will show when intersect
                                    },
                                    shapeOptions: {
                                        color: '#97009c'
                                    }
                                }
                            }}
                        />

                        {pins.map((item, index) => {
                            if (item.length == 1)
                                return (<Marker key={keyPrefix+index}//{keyPrefix+JSON.stringify(item)} 
                                            position={[item[0].lat, item[0].lon]}></Marker>);
                                // The key is keyPrefix + stringified array of coos.
                                // We must add the keyPrefix when the map is editable, since otherwise Leaflet works strange.
                                // By creating a unique keyPrefix each time, we force React to re-render markers/polygons.

                            return (<Polygon key={keyPrefix+index}//{keyPrefix+JSON.stringify(item)+this.props.editable}
                                        positions={item.map(pos => [pos.lat, pos.lon])} />);
                        })}
                    </FeatureGroup>

                </MapContainer>
                {inputControl}
            </div>

        );

        this.inRender = false;
        return retVal;
    }
}
