import React from 'react';
import TranslationManager from '../Managers/Translation';
import AuthenticationManager from '../Managers/Authentication';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import DatePicker from "../Components/DatePicker";

export default class NewCardDialog extends React.Component {
    static contextType = AuthenticationManager.Context;

    state = {
         number: ""
        ,groups: null
    }

    render() {
        if (!this.state.groups) this.state.groups = "" +this.context.getUserData().group; //HACK: never change state uncontrolled, but it's a fast workaround

        return (
            <TranslationManager.Context.Consumer>
            { (context) => (
                <Modal show={this.props.show} centered>
                    <Modal.Header><Modal.Title>{context.getTranslation("$newcard_title")}</Modal.Title></Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={(event) => {event.preventDefault(); this.props.onCreate(this.state)}}>

                            <Form.Group>
                                <Form.Label>{context.getTranslation("$newcard_number")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={context.getTranslation("$newcard_number") + "..."}
                                    value={this.state.number}
                                    onChange={(event) => this.setState({number:event.target.value})}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>{context.getTranslation("$newcard_groups_detailed")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={context.getTranslation("$newcard_groups") + "..."}
                                    value={this.state.groups}
                                    onChange={(event) => this.setState({groups:event.target.value})}
                                />
                            </Form.Group>

                            <Button
                                variant="primary" type="submit"
                                disabled={!(this.state.number && this.state.groups)}
                            >
                                {context.getTranslation("$newcard_submit")}
                            </Button>

                            <Button
                                variant="secondary"
                                onClick={this.props.onCancel}
                            >
                                {context.getTranslation("$newcard_cancel")}
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            )}
            </TranslationManager.Context.Consumer>
        );
    }
}