import $ from "jquery";

if (!window.terman_geocode) {
  window.terman_geocode = function(address) {
        let retVal = null;
        $.ajax({
            async: false,
            dataType: "json",
            url: 'https://ter.webappos.org/nominatim/search/'+address.split(" ").join("%20")+'?format=json&addressdetails=1&limit=1',
            success: function(data) {
                if (data.length==0) {
                    alert("No data returned. Try to change the word order (put the city/town in the end).");
                }
                else
                    retVal = [[ {lat:parseFloat(data[0].lat),lon:parseFloat(data[0].lon)} ]];
            },
            error: function(jqXHR, exception) {
                                                var msg = '';
                                                if (jqXHR.status === 0) {
                                                    msg = 'Not connect.\n Verify Network.';
                                                } else if (jqXHR.status == 404) {
                                                    msg = 'Requested page not found. [404]';
                                                } else if (jqXHR.status == 500) {
                                                    msg = 'Internal Server Error [500].';
                                                } else if (exception === 'parsererror') {
                                                    msg = 'Requested JSON parse failed.';
                                                } else if (exception === 'timeout') {
                                                    msg = 'Time out error.';
                                                } else if (exception === 'abort') {
                                                    msg = 'Ajax request aborted.';
                                                } else {
                                                    msg = 'Uncaught Error.\n' + jqXHR.responseText;
                                                }
                alert("Geocoding error - "+msg);
            }
        });
  
        return retVal;
    };
}

