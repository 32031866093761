import React from 'react';
import DataManager from '../Managers/Data';
import TranslationManager from '../Managers/Translation';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FullRow, ShortRow } from '../Components/BootstrapExtensions';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';


import { MapContainer as LeafletMap, Marker, Polygon, TileLayer, Popup } from 'react-leaflet'

import './../Components/LeafletPatch';

const L = require('leaflet');


var redIcon = new L.Icon({
    iconUrl: '/leaflet-color-markers/img/marker-icon-2x-red.png',
    shadowUrl: '/leaflet-color-markers/img/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});

var yellowIcon = new L.Icon({
	iconUrl: '/leaflet-color-markers/img/marker-icon-2x-yellow.png',
	shadowUrl: '/leaflet-color-markers/img/marker-shadow.png',
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

var greenIcon = new L.Icon({
    iconUrl: '/leaflet-color-markers/img/marker-icon-2x-green.png',
    shadowUrl: '/leaflet-color-markers/img/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});


export default class BigMap extends React.Component {
    static contextType = DataManager.Context;

    constructor(props) {
        super(props);
        this.mapRef = React.createRef();
        this.state = {
            mapProvider: this.getDefaultMapProvider(),
            antiquity: 6, // default
            position: this.getDefaultPosition(),
            filterOptions: ["$filter_res", "$filter_biz", "$filter_all"],
            filterSelected: "$filter_res"
        };

    }

    getMapObj() {
        return this.mapRef.current.leafletElement;
    }

    componentDidMount() {
        this.context.fetchCardsWithMaps((list) => {

            let minLat = 1000;
            let maxLat = -1000;
            let minLon = 1000;
            let maxLon = -1000;

            let position = this.getDefaultPosition();

            list.forEach((card) => {
                if (!card.map)
                    return;
                if (!card.map.pins)
                    return;

                minLat = Math.min(minLat, ...card.map.pins.flat().map(x => x.lat));
                maxLat = Math.max(maxLat, ...card.map.pins.flat().map(x => x.lat));
                minLon = Math.min(minLon, ...card.map.pins.flat().map(x => x.lon));
                maxLon = Math.max(maxLon, ...card.map.pins.flat().map(x => x.lon));

            });

            if (minLat < 1000) {
                let lat = (minLat + maxLat) / 2;
                let lon = (minLon + maxLon) / 2;
                let zoom = Math.min(17,                                         //limit zoom to 17, higher than 17 is too close
                    Math.trunc(
                        Math.min(
                            Math.log2(360 * 0.4 / (maxLat - minLat)),   //0.4 - approximation for 50-60 latitude
                            Math.log2(360 / (maxLon - minLon))
                        ) + 1.0                                     //0.8 - imperical adjustment to zoom
                    )
                );
                position = [lat, lon, zoom];
            }

            this.setState({ cards: list, position: position })

        });
    }

    getDefaultPosition() {
        return [56.944, 24.243, 17];
    }

    getDefaultMapProvider() {
        return Object.getOwnPropertyNames(window.terman_map_providers)[0]; // just the first key
    }

    getMapProviders() {
        return Object.getOwnPropertyNames(window.terman_map_providers);
    }


    openCard(number) {
        //alert(number+"qq");
        //this.props.routing.setRoute("/app/cards?card=" + number);
        window.open("/app/cards?card=" + number);

    }

    render() {

        if (!this.state.cards) return <Spinner animation="border" variant="secondary" style={{ marginTop: "8px", marginLeft: "calc(50% - 16px)" }} />;

        let position = this.state.position;

        let groupedPins = this.state.cards
            .filter(x => x.map && x.map.pins && x.isOlderThanMonths(this.state.antiquity))          //filter out empty or too recent cards
            .filter(item => {
                if (this.state.filterSelected == "$filter_all") return true;
                let isBizSelected = this.state.filterSelected == "$filter_biz";
                let isCardBiz = item.tags && item.tags.includes("$tag_bizness");
                return isBizSelected == isCardBiz;
            })
            .map(x => x.map.pins.map(y => { return {pinsItem:y, card:x} })).flat()                  //get all pins wrapped aropund object {pinsItem, card} to retain reference to card
            .reduce((groupedPins, pinsItemWrapper) => {                                             //group same pins
                let {pinsItem, card} = pinsItemWrapper;
                let key = JSON.stringify(pinsItem);
                groupedPins[key] = groupedPins[key] || {pinsItem:pinsItem, cards:[]};
                groupedPins[key].cards.push(card);
                
                     if (card.isAvailable()) groupedPins[key].hasAvailableCards  = true;
                else if (card.isOnHands())   groupedPins[key].hasOnHandsCards    = true;
                else                         groupedPins[key].hasIncompleteCards = true;

                return groupedPins;
            },{});
        let visiblePins = Object.values(groupedPins) 
            .map((pinObject) => {                                                                    //convert grouped pins to leaflet Marker/Polygon
                let pinsItem = pinObject.pinsItem;
                let cards = pinObject.cards;
                let key = JSON.stringify(pinObject);

                let popup = (
                    <Popup>
                        {
                            cards.map((card, index) => {
                                let color = "black";
                                if (card.isAvailable()) color = "green";
                                if (card.isOnHands()) color = "red";

                                return (
                                    <div key={card.number}>
                                        <font color={color}>
                                            <b><a href="#" onClick={(e)=>this.openCard(card.number)}>{card.number}</a></b> - {card.description}
                                        </font>
                                    </div>
                                );
                            })
                        }
                    </Popup>
                );

                if (pinsItem.length > 1)
                    return <Polygon key={key} positions={pinsItem.map(pos => [pos.lat, pos.lon])}>{popup}</Polygon>
                
                let markerProps = {
                    position: [pinsItem[0].lat, pinsItem[0].lon]
                }
                
                     if (pinObject.hasOnHandsCards && !pinObject.hasAvailableCards)
                        markerProps.icon = redIcon;
                else if (pinObject.hasAvailableCards && !pinObject.hasOnHandsCards && !pinObject.hasIncompleteCards)
                        markerProps.icon = greenIcon;
                else if (pinObject.hasAvailableCards)
                        markerProps.icon = yellowIcon;
                

                return (<Marker key={key} {...markerProps}>{popup}</Marker>);
            });

            /*
        let coos2index = {};
        let visiblePins = [];

        this.state.cards.forEach((card) => {
            if (!card.map)
                return;
            if (!card.map.pins)
                return;

            if (!card.isOlderThanMonths(this.state.antiquity))
                return null; // too young


            return (card.map.pins.map((item) => {

                let key = JSON.stringify(item);
                let i = coos2index[key];
                let vpin;
                if (typeof i === "undefined") {
                    i = visiblePins.length;
                    coos2index[key] = i;
                    vpin = {
                        coos: item,
                        cards: []
                    };
                    visiblePins.push(vpin);
                }
                else
                    vpin = visiblePins[i];

                // update vpin.color and vpin.cards...
                if (card.isAvailable()) {
                    if (typeof vpin.color === "undefined")
                        vpin.color = "green";
                    else
                    if (vpin.color === "red")
                        vpin.color = "yellow";  // red (onHands) + green (isAvailable) = yellow (mixed)
                    vpin.cards.push({
                        number: card.number,
                        description: card.description,
                        color: "green"
                    });
                }
                else
                if (card.isOnHands()) {
                    if (typeof vpin.color === "undefined")
                        vpin.color = "red";
                    else
                    if (vpin.color === "green")
                        vpin.color = "yellow";  // green (isAvailable) + red (onHands) = yellow (mixed)
                    vpin.cards.push({
                        number: card.number,
                        description: card.description,
                        color: "red"
                    });                
                }
                else {
                    vpin.cards.push({
                        number: card.number,
                        description: card.description
                    });
                }

            }));

        });

        let keyPrefix = "";
        if (this.props.editable) {
            keyPrefix = new Date().getTime();
        }

        visiblePins = visiblePins.map((vpin) => {

                let icon = null;
                if (vpin.color === "red")
                    icon = redIcon;
                else
                if (vpin.color === "yellow")
                    icon = yellowIcon;
                else
                if (vpin.color === "green")
                    icon = greenIcon;
                
                let popup = <Popup>{vpin.cards.map((card, index)=>
                    {
                        console.log("card = ",card);
                        return (<div key={index}>
                            <font color={card.color?card.color:"black"}><b><a href="#" onClick={(e)=>this.openCard(card.number)}>{card.number}</a></b> - {card.description}</font>
                        </div>)
                        })}
                </Popup>;


                let key = keyPrefix+JSON.stringify(vpin.coos);
                if (vpin.coos.length == 1) {
                    if (icon == null)
                        return (<Marker key={key} position={[vpin.coos[0].lat, vpin.coos[0].lon]}>{popup}</Marker>);
                    else
                        return (<Marker key={key} icon={icon} position={[vpin.coos[0].lat, vpin.coos[0].lon]}>{popup}</Marker>);
                }
                else
                    return (<Polygon key={key} positions={vpin.coos.map(pos => [pos.lat, pos.lon])}>{popup}</Polygon>);

        });
*/
        return (
            <TranslationManager.Context.Consumer>
                {(context) => (
                    <FullRow>
                        <Col xs={12} className="cardspage-viewheader">
                            <Row className="no-gutters">
                                <Col xs="auto">
                                    <ShortRow>
                                        <Col><div style={{ textAlign: "center", width: "100%" }}><div style={{ display: "inline-block" }}>{context.getTranslation("$" + this.state.antiquity + "months")}</div></div></Col>
                                    </ShortRow>
                                    <ShortRow style={{marginTop:-5}}>
                                        <Col xs="auto" style={{ fontSize: "10pt" }}> {context.getTranslation("$older")}&nbsp;</Col>
                                        <Col>
                                            {/*<Form.Control style={{ display: "inline-block" }} type="range" min="0" max="12" list="antiquity" onChange={(e) => this.setState({ antiquity: 12 - e.target.value })} />
                                            */}
                                            <input type="range" className="form-range" value={12-this.state.antiquity}  min="0" max="12" list="antiquity" onChange={(e) => this.setState({ antiquity: 12 - e.target.value })} />
                                        </Col>
                                        <Col xs="auto" style={{ fontSize: "10pt" }}>&nbsp;{context.getTranslation("$newer")}</Col>

                                    </ShortRow>
                                </Col>
                                <Col>
                                    <Dropdown>
                                        <Dropdown.Toggle>
                                            {context.getTranslation(this.state.filterSelected)}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu style={{ zIndex: 10000 }}>
                                        {
                                                this.state.filterOptions.map((item, index) => (
                                                    <Dropdown.Item
                                                        key={index}
                                                        onClick={() => this.setState({ filterSelected: item })}
                                                    >
                                                        {context.getTranslation(item)}
                                                    </Dropdown.Item>
                                                ))
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                                <Col />
                                <Col xs="auto">
                                    <Dropdown>
                                        <Dropdown.Toggle>
                                            {context.getTranslation("$" + this.state.mapProvider)}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu style={{ zIndex: 10000 }}>
                                            {
                                                this.getMapProviders().map((item, index) => (
                                                    <Dropdown.Item
                                                        key={index}
                                                        onClick={() => this.setState({ mapProvider: item })}
                                                    >
                                                        {context.getTranslation("$" + item)}
                                                    </Dropdown.Item>
                                                ))
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} className="cardspage-viewcontent" style={{ overflow: "auto" }}>

                            <div style={{ height: "100%" }}>
                                <LeafletMap
                                    ref={this.mapRef}
                                    center={position.slice(0, 2)}
                                    zoom={position[2]}
                                    style={{ width: "100%", height: "100%" }}
                                >
                                    <TileLayer url={window.terman_map_providers[this.state.mapProvider]} />
                                    {visiblePins}

                                </LeafletMap>
                            </div>
                    </Col>
                    </FullRow>
                )}
            </TranslationManager.Context.Consumer>
        );
    }
}