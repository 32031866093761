import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

////// Leaflet marker icon hack ==>                                /////
////// (In addition, we had to copy marker-shadow.png to ./public) /////
L.Icon.Default.prototype._getIconUrl = function (name) {

    L.Icon.Default.imagePath = this._detectIconPath(name);
  
    // @option imagePath: String
    // `L.Icon.Default` will try to auto-detect the absolute location of the
    // blue icon images. If you are placing these images in a non-standard
    // way, set this option to point to the right absolute path.
    var path = this.options.imagePath || L.Icon.Default.imagePath;
    let retVal = path.indexOf("data:") === 0 ? path : "/"+path + L.Icon.prototype._getIconUrl.call(this, name);
    return retVal;
  };
  L.Icon.Default.prototype._detectIconPath = function (name) {
    var el = L.DomUtil.create('div', 'leaflet-default-' + name + '-path', document.body);
    var path = L.DomUtil.getStyle(el, 'background-image') ||
      L.DomUtil.getStyle(el, 'backgroundImage');   // IE8
  
    document.body.removeChild(el);
  
    return path.indexOf('url') === 0 ? path.replace(/^url\([\"\']?/, '').replace(/(marker-icon\.png)?[\"\']?\)$/, '') : '';
  };
  ////// <== Leaflet marker icon hack /////
  
  export default L;
