import React from 'react';
import TranslationManager from '../Managers/Translation';
import Spinner from 'react-bootstrap/Spinner';
//import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';

import {FullRow, ShortRow as Row} from '../Components/BootstrapExtensions';
import MapComponent from '../Components/Map';

// This is the third column in the page. It contains buttons for switching between different maps as well as the map componenet (Components/Map).
export default class MapView extends React.Component {

    getDefaultMapProvider() {
        return Object.getOwnPropertyNames(window.terman_map_providers)[0]; // just the first key
    }

    getMapProviders() {
        return Object.getOwnPropertyNames(window.terman_map_providers);
    }

    setMapProvider(mapProvider) {
        this.props.onChange({map:{...this.props.card.map,  simpleTiles: mapProvider}});
    }

    render() {
        if (this.props.card === undefined) return null;
        if (!this.props.card) return <Spinner animation="border" variant="secondary" style={{marginTop:"8px",marginLeft:"calc(50% - 16px)"}}/>
        
        let cardsMap = this.props.card.map || {};
        let mapProvider = cardsMap.simpleTiles || this.getDefaultMapProvider();
        if (!window.terman_map_providers[mapProvider])
          mapProvider = this.getDefaultMapProvider();

        return (
            <TranslationManager.Context.Consumer>
            { (context) => (
                <FullRow id="mapview">
                    <Col xs={12} className="cardspage-viewheader">
                        <Row>
                            <Col/>
                            <Col xs="auto">
                                <Dropdown disabled={this.props.blockInEditMode}>
                                    <Dropdown.Toggle disabled={this.props.blockInEditMode}>
                                        {context.getTranslation("$"+mapProvider)}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu style={{ zIndex: 10000 }} disabled={this.props.blockInEditMode}>
                                    {
                                        this.getMapProviders().map((item,index) => (
                                            <Dropdown.Item key={index} onClick={() => this.setMapProvider(item)}>
                                                {context.getTranslation("$"+item)}
                                            </Dropdown.Item>
                                        ))
                                    }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} className="cardspage-viewcontent" style={{overflow:"none"}}>
                        <MapComponent
                            editable={this.props.editMode}
                            map={cardsMap}
                            tilesURL={window.terman_map_providers[mapProvider]}
                            geocode={window.terman_geocode}
                            onChange={(newMap) => this.props.onChange({map: newMap})}
                            onEditStart={this.props.onEditStart}
                            onEditStop={this.props.onEditStop}
                        />
                    </Col>
                </FullRow>
            )}
            </TranslationManager.Context.Consumer>
        );
    }
}