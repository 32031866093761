import React from 'react';
// React 17: import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client'; // React 18

import Application from './Application.jsx';

import "./Var/Date.js";
import "./Var/terman_map_providers.js";
import "./Var/terman_geocode.js";

import './index.css';

const container = document.getElementById('app-root');
const root = createRoot(container);
root.render(<Application />);
