import React from 'react';
import TranslationManager from '../Managers/Translation';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import LanguageSelector from './LanguageSelector';

export default class Toolbar extends React.Component {
    state = {expanded: false}
    render() {
        return (
            <TranslationManager.Context.Consumer>
            { (context) => (
                <Navbar fixed="top" variant="dark" expand="sm" expanded={this.state.expanded} onToggle={(expanded) => this.setState({expanded:expanded})}>
                    <Navbar.Toggle />
                    {!this.state.expanded && // shown when the hamburger (expand) button is not visible
                        <Navbar.Collapse>
                            <Nav onClick={() => this.setState({expanded:false})}>
                                {this.props.children}
                            </Nav>
                        </Navbar.Collapse>
                    }              
                    <Navbar.Brand>{context.getTranslation("$title")}</Navbar.Brand>
                    <LanguageSelector />

                    {this.state.expanded && // shown when the hamburger button is clicked (expanded)
                        <Navbar.Collapse className="col-md-0">
                            <Nav onClick={() => this.setState({expanded:false})} style={{padding:10}}>
                                {this.props.children}
                            </Nav>
                        </Navbar.Collapse>                     
                    }                        
                </Navbar>
            )}
            </TranslationManager.Context.Consumer>
        );
    }
}