import React from 'react';
import DataManager from '../Managers/Data';
import TranslationManager from '../Managers/Translation';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import CardsTotalsReport from '../Views/CardsTotals';

import DatePicker from "../Components/DatePicker";
import "react-datepicker/dist/react-datepicker.css";

export default class CardsReport extends React.Component {
    static contextType = DataManager.Context;

    state = {
         dateFrom: new Date()
        ,dateTo: new Date()
    }

    componentDidMount() {
        this.refreshList();
        this.refreshListInterval = setInterval(() => this.refreshList(), 30 * 1000);
    }

    refreshList() {
        this.context.fetchCardList((list) => this.setState({cards: list}));
    }

    render() {
        return (
            <TranslationManager.Context.Consumer>
            { (context) => (
                <React.Fragment>
                    <Row className="no-gutters">
                        <Col xs="auto">
                            <div style={{padding:"0.25em",boxSizing:"border-box"}}>
                                {context.getTranslation("$history_from")}
                                &nbsp;
                                <DatePicker
                                    className="form-control"
                                    selected={this.state.dateFrom}
                                    onChange={newDate => this.setState({dateFrom:newDate})}
                                    dateFormat="dd.MM.yyyy"
                                />
                            </div>
                        </Col>
                        <Col xs="auto">
                            <div style={{padding:"0.25em",boxSizing:"border-box"}}>
                                {context.getTranslation("$history_to")}
                                &nbsp;
                                <DatePicker
                                    className="form-control"
                                    selected={this.state.dateTo}
                                    onChange={newDate => this.setState({dateTo:newDate})}
                                    dateFormat="dd.MM.yyyy"
                                />
                            </div>
                        </Col>
                    </Row>
                    <CardsTotalsReport
                        cards={this.state.cards}
                        dateFrom={this.state.dateFrom}
                        dateTo={this.state.dateTo}
                    />
                </React.Fragment>
            )}
            </TranslationManager.Context.Consumer>
        );
    }
}