import React from 'react';
import TranslationManager from '../Managers/Translation';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CardTagsList from './CardTagsList';
import DynamicList from './DynamicList';
import ListGroup from 'react-bootstrap/ListGroup';

export default class CardList extends React.Component {
    render() {
        if (!this.props.list) return null;
        return (
            <TranslationManager.Context.Consumer>
            { (context) => (
                <ListGroup className="dynamic-list">
                
                    {
                        this.props.list.map(card => (
                            <ListGroup.Item key={card.rawObject._id} onClick={() => this.props.onItemClick(card)}>
                                <div className="d-flex" style={{height:"3em"}}>
                                    <Col xs style={{overflow:"hidden", textOverflow: "ellipsis",height:"1.5em",padding:"0 0.25em"}}>
                                        {card.description}
                                    </Col>
                                    <Col xs="auto">
                                        <div className="d-flex">
                                            <Col style={{fontWeight:"bold"}} className="text-right">
                                                {card.number}
                                            </Col>
                                        </div>
                                        <div className="d-flex">
                                            <Col style={{fontSize:"0.75em"}} className="text-right">
                                                {card.date && card.date.format(context.getTranslation("$date_format"))}
                                            </Col>
                                        </div>
                                    </Col>
                                </div>
            
                                <div className="d-flex">
                                    <CardTagsList tags={card.tags} readOnly/>
                                </div>
                            </ListGroup.Item>
                        ))
                    }
                </ListGroup>
            )}
            </TranslationManager.Context.Consumer>
        );

        /*
        <DynamicList list={this.props.list} itemSizeEm={5.5} onItemClick={(item) => this.props.onItemClick(item)}>
        {(card) => (    
        )}
        </DynamicList>
        */
    }
}