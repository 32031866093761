import React from 'react';
import TranslationManager from '../Managers/Translation';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import DatePicker from "../Components/DatePicker";

export default class ReturnDialog extends React.Component {
    state = {
        date: new Date()
    }
    /*
    <Form.Control
        type="date"
        placeholder={context.getTranslation("$return_date") + "..."}
        value={this.state.date.format("yyyy-MM-dd")}
        onChange={(event) => this.setState({date:new Date(event.target.value)})}
    />
    */

    render() {
        return (
            <TranslationManager.Context.Consumer>
            { (context) => (
                <Modal show={this.props.show} centered>
                    <Modal.Header><Modal.Title>{context.getTranslation("$return_title")}</Modal.Title></Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={(event) => {event.preventDefault(); this.props.onReturn(this.state)}}>
                            <Form.Group>
                                <Form.Label>{context.getTranslation("$return_date")}</Form.Label>
                                <div>
                                    <DatePicker
                                        className="form-control"
                                        selected={this.state.date}
                                        onChange={newDate => this.setState({date:newDate})}
                                        dateFormat="dd.MM.yyyy"
                                    />
                                </div>
                            </Form.Group>

                            <Button variant="primary" type="submit">
                                {context.getTranslation("$return_submit")}
                            </Button>

                            <Button variant="secondary" onClick={this.props.onCancel}>
                                {context.getTranslation("$return_cancel")}
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            )}
            </TranslationManager.Context.Consumer>
        );
    }
}