import React from 'react';
import Card from '../Entities/Card.jsx';
import AuthenticationManager from './Authentication.jsx';

export default class DataManager extends React.Component {
    static contextType = AuthenticationManager.Context;

    state = {dataProvider: this.getDataProvider() }

    getDataProvider() {
        return {
            fetchCardList: (callback) => this.fetchCardList(callback),
            fetchCardsWithMaps: (callback) => this.fetchCardsWithMaps(callback),
            fetchCard: (cardId,callback) => this.fetchCard(cardId,callback),
            saveCard: (card,callback) => this.saveCard(card,callback),
            removeCard: (card,callback) => this.removeCard(card,callback)
        };
    }

    fetchCardList(callback) {
        fetch("/data/cards", {
            credentials: "same-origin"
        }).then(response => {
            if (response.ok) {
                response.json().then(list => callback(list.map(card => new Card(card))))
                return;
            }
            
            if (response.status == "401") {
                this.context.showLoginForm();
            }
        }).catch(error => console.log(error));
    }

    fetchCardsWithMaps(callback) {
        fetch("/cards/with_maps", {
            credentials: "same-origin"
        }).then(response => {
            if (response.ok) {
                response.json().then(list => callback(list.map(card => new Card(card))))
                return;
            }
            
            if (response.status == "401") {
                this.context.showLoginForm();
            }
        }).catch(error => console.log(error));
    }


    fetchCard(cardId, callback) {
        fetch("/data/cards/number/" + encodeURIComponent(cardId), {
            credentials: "same-origin"
        })
        .then(response => {
            if (response.ok) {
                response.json().then(card => {
                    if (card && card.length)
                        callback(new Card(card[0]));
                    else
                        callback(null);
                });
                return;
            }
            if (response.status == "401") {
                this.context.showLoginForm();
            }
        })
        .catch(error => console.log(error));
    }

    saveCard(card, callback) {
        card = card.prepareForSave();
        fetch("/data/cards/" + encodeURIComponent(card._id), {
            method: 'PUT',
            headers: {
            'Content-Type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(card)
        })
        .then(response => {
            if (response.ok) {
                response.json().then(data => {if (callback) callback();});
                return;
            }
            if (response.status == "401") {
                this.context.showLoginForm();
            }
        })
        .catch(error => console.log(error));
    }

    removeCard(card, callback)
    {
        card = card.prepareForSave();
        fetch("/cards/" + encodeURIComponent(card._id) + "/" +encodeURIComponent(card._rev), {
            method: 'DELETE',
            credentials: "same-origin"
        })
        .then(response => {
            if (response.ok) {
                if (callback) callback();
                return;
            }
            if (response.status == "401") {
                this.context.showLoginForm();
            }
        })
        .catch(error => console.log(error));
    }

    render() {
        return (
            <DataManager.Context.Provider value={this.state.dataProvider}>
                {this.props.children}
            </DataManager.Context.Provider>
        );
    }
}

DataManager.Context = React.createContext();