/* This file is used to define supported map providers (BalticMaps, OpenStreetMaps, HereMaps, etc.) with their respective tile servers. */

 if (!window.terman_map_providers) {
   window.terman_map_providers = {
//    BalticMaps: 'http://services.ter.webappos.org/allbmtiles/{z}/{x}/{y}.png', // commented out by SK @2021-11-07
    OpenStreetMap: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' // replaced wmflabs with openstreetmap by SK @2022-01-23
    // variants:
    // "http://otile1.mqcdn.com/tiles/1.0.0/map/{z}/{x}/{y}.png"; // seems to be free
    // "http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"; // not so free
    // 'http://{s}.tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png'; // seems to be free
  };
}
