export default class CardLocation {
    constructor(locationData) {
        this.rawObject = locationData;
        if (!this.rawObject.refusals) this.rawObject.refusals = {};
        if (!this.rawObject.codes) this.rawObject.codes = "";
        if (!this.rawObject.comments) this.rawObject.comments = "";
    }

    refusalsSimpleString() {
        return Object.keys(this.rawObject.refusals)
                     .join(", ");
    }

    refusalsFullString() {
        return Object.keys(this.rawObject.refusals)
                     .map(key => key + ": " + this.rawObject.refusals[key])
                     .join(", ");
    }

    get refusals()  {return this.rawObject.refusals;}
    get codes()     {return this.rawObject.codes;}
    get comments()  {return this.rawObject.comments;}

    get address()   {return this.rawObject.address;}
    get info()      {return this.rawObject.info;}
    get link()      {return this.rawObject.link;}
}