import React from 'react';
import Nav from 'react-bootstrap/Nav';

export default class RoutingManager extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            routing: {
                route: document.location.pathname
                ,setRoute: (r) => this.setRoute(r)
                ,compareRoute: (r) => this.compareRoute(r)
                ,getParameter: (r) => this.getParameter(r)
            }
        }
        this.updateRouteOnPopState = this.updateRouteOnPopState.bind(this);
    }

    componentDidMount() {
        window.addEventListener("popstate", this.updateRouteOnPopState);
    }

    componentWillUnmount() {
        window.removeEventListener("popstate", this.updateRouteOnPopState);
    }

    updateRouteOnPopState() {
        this.setState({routing: {
            route: document.location.pathname
            ,setRoute: (r) => this.setRoute(r)
            ,compareRoute: (r) => this.compareRoute(r)
            ,getParameter: (r) => this.getParameter(r)
        }});
    }

    setRoute(route) {
        window.history.pushState(null,document.title,route);
        this.setState({routing: {
            route: document.location.pathname
            ,setRoute: (r) => this.setRoute(r)
            ,compareRoute: (r) => this.compareRoute(r)
            ,getParameter: (r) => this.getParameter(r)
        }});
    }

    compareRoute(route) {
        let current_pathname = this.state.routing.route;
        if (current_pathname[current_pathname.length -1] !== "/")  current_pathname += "/";

        let target_pathname = route;
        if (target_pathname[target_pathname.length -1] !== "/")  target_pathname += "/";

        return current_pathname === target_pathname;
    }

    getParameter(param) {
        //console.log("getParameter route:",this.state.routing.route);
        let search = new URLSearchParams(document.location.search);
        return search.get(param);
    }

    render() {
        return (
            <RoutingManager.Context.Provider value={this.state.routing}>
                {this.props.children}
            </RoutingManager.Context.Provider>
        );
    }
}

export class Link extends React.Component {
    render() {
        return (
            <RoutingManager.Context.Consumer>
                {(routing) => (
                    <Nav.Link onClick={() => routing.setRoute(this.props.to)}>{this.props.children}</Nav.Link>
                )}
            </RoutingManager.Context.Consumer>
        );
    }
}

export class Route extends React.Component {
    render() {
        return (
            <RoutingManager.Context.Consumer>
                {(routing) => routing.compareRoute(this.props.path) && this.props.children}
            </RoutingManager.Context.Consumer>
        );
    }
}


//Managers.Routing = ManagersRouting;
//Managers.Routing.Link = ManagersRoutingLink;
//Managers.Routing.Route = ManagersRoutingRoute;

RoutingManager.Context = React.createContext();