import React from 'react';

export default class TranslationManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: this.props.defaultLanguage || localStorage.getItem("language") || navigator.language || "ru-RU"
            ,setLanguage: (l) => this.setLanguage(l)
            ,getTranslation: (l) => this.getTranslation(l)
            ,getLanguagesList: () => this.getLanguagesList()
        }
    }

    setLanguage(language) {
        localStorage.setItem("language",language);
        this.setState({language:language});
    }

    getLanguagesList() {
        return ["en-GB","lv","ru-RU"];
    }

    getTranslation(text) {
        let lang = this.state.language;
        let i = lang.indexOf("-");
        if (i>0)
            lang = lang.substring(0,i);
        if (lang!="ru" && lang!="lv" && lang!="en")
            lang = "en";

        if (lang === "en") {
            switch (text) {
                case "$title": return "TerMan";

                case "$login": return "Login";
                case "$username": return "Username";
                case "$password": return "Password";
                case "$search": return "Search";
                case "$back": return "Back";

                case "$page_cards": return "Cards";
                case "$page_reports": return "Reports";

                case "en-US": return "English";
                case "en-GB": return "English";
                case "lv": return "Latvian";
                case "ru-RU": return "Russian";

                case "$tag_onhands": return "On hands";
                case "$tag_available": return "Available";
                case "$tag_dirty": return "Not Finished";
                case "$tag_toprint": return "No card";
                case "$tag_nopins": return "No pins";

                case "$date_format": return "dd.MM.yyyy";

                case "$sort_name": return "Order by Name";
                case "$sort_number": return "Order by Number";
                case "$sort_date": return "Order by Date";
                
                case "$map": return "Map";
                case "$s12": return "S-12";

                case "$giveout": return "Give out";
                case "$return": return "Return";
                case "$save": return "Save";
                case "$dont_save": return "Cancel";
                case "$split": return "Split";
                case "$delete": return "Delete";
                case "$delete_text": return "Enter card number to delete";

                case "$add_location": return "Add";

                case "$giveout_title": return "Give out territory";
                case "$giveout_person": return "Publisher";
                case "$giveout_group": return "Congregation";
                case "$giveout_date": return "Date";
                case "$giveout_submit": return "Give out";
                case "$giveout_cancel": return "Cancel";

                case "$return_title": return "Return territoty";
                case "$return_date": return "Date";
                case "$return_submit": return "Return";
                case "$return_cancel": return "Cancel";

                case "$BalticMaps": return "Baltic Maps";
                case "$OpenStreetMap": return "OpenStreetMap";
                case "$HereMaps": return "Here Maps";

                case "$history_who": return "Who";
                case "$history_group": return "Congr.";
                case "$history_from": return "From";
                case "$history_to": return "To";

                case "$total_cards_count": return "Total cards count:";
                case "$total_visited_cards_count": return "Total visited cards count:";
                case "$total_visited_group_cards_count": return "Total visited by your group cards count:";
                case "$total_returned_cards_count": return "Total returned cards count:";
                case "$total_returned_group_cards_count": return "JTotal returned by tour group cards count:";

                case "$cannot_draw_that": return "Sorry, you can\'t draw that!";
                case "$enter_pins": return "A [[{lat,lon}]]-like array or the address (as a string, city in the end):";
                case "$page_bigmap": return "Overview";

                case "$older": return "older";
                case "$newer": return "newer";
                case "$0months": return "all";
                case "$1months": return "older than 1 month";


                case "$newcard_title": return "New card";
                case "$newcard_number": return "Number";
                case "$newcard_groups": return "Congregations";
                case "$newcard_groups_detailed": return "Congregations (comma separated)";
                case "$newcard_submit": return "Create";
                case "$newcard_cancel": return "Cancel";

                case "$filter_res": return "Residential";
                case "$filter_biz": return "Business";
                case "$filter_all": return "All";

                default: break;
            }

            if (text.indexOf("$")==0 && text.indexOf("months")>0)
                return "older than "+text.substring(1,text.indexOf("months"))+" months";
        }

        if (lang === "lv") {
            switch (text) {
                case "$title": return "TerMan";

                case "$login": return "Ieiet";
                case "$username": return "Lietotājs";
                case "$password": return "Parole";
                case "$search": return "Mēklēt";
                case "$back": return "Atpakaļ";

                case "$page_cards": return "Saraksts";
                case "$page_reports": return "Atskaites";

                case "en-US": return "Angļu (EN)";
                case "en-GB": return "Angļu (EN)";
                case "lv": return "Latviešu (LV)";
                case "ru-RU": return "Krievu (RU)";

                case "$tag_onhands": return "Uz rokām";
                case "$tag_available": return "Pieejams";
                case "$tag_dirty": return "Nav pabeigts";
                case "$tag_toprint": return "Nav izdrukāts";
                case "$tag_nopins": return "Nav lokācijas";

                case "$date_format": return "dd.MM.yyyy";

                case "$sort_name": return "Kārtot pēc nosaukuma";
                case "$sort_number": return "Kārtot pēc numura";
                case "$sort_date": return "Kārtot pēc datuma";

                case "$map": return "Karte";
                case "$s12": return "S-12";

                case "$giveout": return "Izsniegt";
                case "$return": return "Atgriezt";
                case "$save": return "Saglabāt";
                case "$dont_save": return "Atcelt";
                case "$split": return "Sadalīt";
                case "$delete": return "Izdzēst";
                case "$delete_text": return "Ievadiet territorijas numuru, lai izdzēstu";

                case "$add_location": return "Pievienot";

                case "$giveout_title": return "Izsniegt territoriju";
                case "$giveout_person": return "Sludinātājs";
                case "$giveout_group": return "Draudze";
                case "$giveout_date": return "Datums";
                case "$giveout_submit": return "Izsniegt";
                case "$giveout_cancel": return "Atcelt";

                case "$return_title": return "Atgriezt territoriju";
                case "$return_date": return "Datums";
                case "$return_submit": return "Atgriezt";
                case "$return_cancel": return "Atcelt";

                case "$BalticMaps": return "Baltic Maps";
                case "$OpenStreetMap": return "OpenStreetMap";
                case "$HereMaps": return "Here Maps";

                case "$history_who": return "Kas";
                case "$history_group": return "Draudze";
                case "$history_from": return "No";
                case "$history_to": return "Līdz";

                case "$total_cards_count": return "Teritoriju kopskaits:";
                case "$total_visited_cards_count": return "Izsludināto teritoriju skaits:";
                case "$total_visited_group_cards_count": return "Izsludināto no jūsus draudzes puses teritoriju skaits";
                case "$total_returned_cards_count": return "Atgriezto teritoriju kopskaits:";
                case "$total_returned_group_cards_count": return "Jūsu draudzes atgriezto teritoriju kopskaits:";


                case "$cannot_draw_that": return "Atvainojiet, šādi nav atļauts zīmēt!";
                case "$enter_pins": return "Ievadiet masīvu formātā [[{lat,lon}]], vai arī - adresi (pilsēta jāraksta beigās):";
                case "$page_bigmap": return "Kopskats";

                case "$older": return "vecāki";
                case "$newer": return "jaunāki";
                case "$0months": return "visi";
                case "$1months": return "vecāki par 1 mēnesi";

                case "$newcard_title": return "Jauna territorija";
                case "$newcard_number": return "Numurs";
                case "$newcard_groups": return "Draudzes";
                case "$newcard_groups_detailed": return "Draudzes (atdalot ar komatu)";
                case "$newcard_submit": return "Izveidot";
                case "$newcard_cancel": return "Atcelt";

                case "$filter_res": return "Dzīvojāmās";
                case "$filter_biz": return "Biznesa";
                case "$filter_all": return "Visas";

                default: break;
            }

            if (text.indexOf("$")==0 && text.indexOf("months")>0)
                return "vecāki par "+text.substring(1,text.indexOf("months"))+" mēnešiem";

        }


        if (lang === "ru") {
            switch (text) {
                case "$title": return "TerMan";

                case "$login": return "Войти";
                case "$username": return "Пользователь";
                case "$password": return "Пароль";
                case "$search": return "Поиск";
                case "$back": return "Назад";

                case "$page_cards": return "Участки";
                case "$page_reports": return "Отчеты";

                case "en-US": return "Английский (EN)";
                case "en-GB": return "Английский (EN)";
                case "lv": return "Латышский (LV)";
                case "ru-RU": return "Русский язык (RU)";

                case "$tag_onhands": return "На руках";
                case "$tag_available": return "Доступен";
                case "$tag_dirty": return "Не введен";
                case "$tag_toprint": return "Нет карточки";
                case "$tag_nopins": return "Пустая карта";

                case "$date_format": return "dd.MM.yyyy";

                case "$sort_name": return "Сортировать по названию";
                case "$sort_number": return "Сортировать по номеру";
                case "$sort_date": return "Сортировать по дате";

                case "$map": return "Карта";
                case "$s12": return "S-12";

                case "$giveout": return "Выдать";
                case "$return": return "Вернуть";
                case "$save": return "Сохранить";
                case "$dont_save": return "Отменить";
                case "$split": return "Разделить";
                case "$delete": return "Удалить";
                case "$delete_text": return "Введите номер карточки, чтобы удалить";

                case "$add_location": return "Добавить";

                case "$giveout_title": return "Выдать участок";
                case "$giveout_person": return "Возвещатель";
                case "$giveout_group": return "Собрание";
                case "$giveout_date": return "Дата";
                case "$giveout_submit": return "Выдать";
                case "$giveout_cancel": return "Отменить";

                case "$return_title": return "Вернуть участок";
                case "$return_date": return "Дата";
                case "$return_submit": return "Вернуть";
                case "$return_cancel": return "Отменить";

                case "$BalticMaps": return "Baltic Maps";
                case "$OpenStreetMap": return "OpenStreetMap";
                case "$HereMaps": return "Here Maps";

                case "$history_who": return "Кто";
                case "$history_group": return "Собр.";
                case "$history_from": return "От";
                case "$history_to": return "До";

                case "$total_cards_count": return "Общее число участков:";
                case "$total_visited_cards_count": return "Общее число обработанных участков:";
                case "$total_visited_group_cards_count": return "Число обработанных участков вашим собранием:";
                case "$total_returned_cards_count": return "Общее число сданных участков:";
                case "$total_returned_group_cards_count": return "Общее число сданных участков вашим собранием:";

                case "$cannot_draw_that": return "К сожалению, так рисовать нельзя!";
                case "$enter_pins": return "Массив в виде [[{lat,lon}]] или же просто адрес (с городом в конце):";
                case "$page_bigmap": return "Обзор";

                case "$older": return "давние";
                case "$newer": return "недавние";
                case "$0months": return "все";
                case "$1months": return "старше 1 месяца";

                case "$newcard_title": return "Новая карточка";
                case "$newcard_number": return "Номер";
                case "$newcard_groups": return "Собрания";
                case "$newcard_groups_detailed": return "Собрания (через запятую)";
                case "$newcard_submit": return "Создать";
                case "$newcard_cancel": return "Отменить";

                case "$filter_res": return "Жилые";
                case "$filter_biz": return "Бизнес";
                case "$filter_all": return "Все";

                default: break;
            }

            if (text.indexOf("$")==0 && text.indexOf("months")>0)
                return "старше "+text.substring(1,text.indexOf("months"))+" месяцев";
        }

        if (text.startsWith("$tag_")) return text.substring(5);
        return text;
    }

    render() {
        return (
            <TranslationManager.Context.Provider value={this.state}>
                {this.props.children}
            </TranslationManager.Context.Provider>
        );
    }
}

//Managers.Translation = ManagersTranslation;
TranslationManager.Context = React.createContext();
